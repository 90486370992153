/**
 * @file Index.js for the DE Landing App
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Store } from 'redux';
import AppRouter from './routers/AppRouter';
import * as Settings from './Settings';
import { ErrorNotification } from './components';
import Logger from 'abb-webcore-logger/Logger';
import { setupRedux } from './redux/Store';
import { Provider } from 'react-redux';
import { LoadMask } from 'de-common-client/nextgen/components';
import ThemeProvider from 'webcore-ux/nextgen/theme/ThemeProvider';

declare global {
    interface Window {
        isDevelopmentEnvironment: boolean;
        webpackHotUpdate: unknown;
    }
}

// Since we turn on hot updates, a function is inserted by webpack-dev-server at runtime, we use it to detect if we
// are in dev mode or not.

function setDevelopmentMode() {
    window.isDevelopmentEnvironment = !!window.webpackHotUpdate;
}

setDevelopmentMode();

/**
 * Called if there is an error starting the app
 *
 * @param {*} reason - error reason
 * @param reactDom - injected react dom
 */

function onError(reason: string, reactDom = ReactDOM) {
    Logger.error(reason);

    // Render the error
    reactDom.render(
        <div>
            <ErrorNotification error={reason} />
        </div>,
        document.getElementById('root')
    );
}

/**
 * Called to render the App
 * @param {object} store - The Redux store used for the app
 * @param reactDom - injected react dom
 */

function renderTheApp(store: Store, reactDom = ReactDOM) {
    Logger.info('Rendering the App');

    // Render the app
    reactDom.render(
        <Provider store={store}>
            <AppRouter />
        </Provider>,
        document.getElementById('root')
    );
}

/**
 * Called to render the Loading Indicator
 * @param reactDom - injected react dom
 */

function renderLoadingIndicator(reactDom = ReactDOM) {
    Logger.info('Initializing Application');

    // Render the Loading Indicators
    reactDom.render(
        <ThemeProvider>
            <LoadMask />
        </ThemeProvider>,
        document.getElementById('root')
    );
}

/**
 * Main Entry Point that launches the app
 */

async function loadTheApp(reactDom = ReactDOM) {
    try {
        await Settings.loadBrowserWebSettings();
        renderLoadingIndicator(reactDom);
        renderTheApp(setupRedux(), reactDom);
    } catch (err) {
        onError(err, reactDom);
    }
}

loadTheApp().then(() => {
    Logger.info('app loaded.');
});

export { loadTheApp, onError, renderTheApp, renderLoadingIndicator, setupRedux, setDevelopmentMode };
