/**
 * @file Main Routing component for DE Landing UI
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isValidElementType } from 'react-is';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginRouter from './LoginRouter';
import InvalidRouter from './InvalidRouter';
import App from '../App';
import { CombinedState } from '../redux/types';

type AppRouterProps = {
    isUserSignedOn: boolean;
};

type AuthenticationRequiredRouteProps = {
    exact: boolean;
    path: string;
    Component: Function;
    isUserLoggedIn: boolean;
};

/**
 * This is the main Router entry point, this directs the url to the location needed
 */
class AppRouter extends React.Component<AppRouterProps> {
    static propTypes = {};

    render() {
        return (
            <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
                <Switch>
                    <AuthenticationRequiredRoute exact path={'/'} Component={App} isUserLoggedIn={this.props.isUserSignedOn} />
                    <Route path="/login" component={LoginRouter} />
                    <Route component={InvalidRouter} />
                </Switch>
            </BrowserRouter>
        );
    }
}

function AuthenticationRequiredRoute({ Component, isUserLoggedIn, ...rest }: AuthenticationRequiredRouteProps): JSX.Element {
    return (
        <Route
            {...rest}
            render={(props) => {
                return isUserLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
}

AuthenticationRequiredRoute.propTypes = {
    component: (props: never, propName: string) => {
        if (props[propName] && !isValidElementType(props[propName])) {
            return new Error(`Invalid prop 'component' supplied to 'AuthenticationRequiredRoute': the prop is not a valid React component`);
        }
    },
    isUserLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.object,
};

AppRouter.propTypes = {
    isUserSignedOn: PropTypes.bool.isRequired,
};

// Map Redux store state to props for this component
const mapStateToProps = (state: CombinedState) => {
    return {
        isUserSignedOn: state.authentication.isUserSignedOn,
    };
};

export default connect(mapStateToProps)(AppRouter);
