/**
 * @file LandingPage component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import React, { ReactElement, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getCombinedState } from '../../redux/actions';
import WidgetCardGridLayout from 'de-common-client/nextgen/components/WidgetCardGridLayout/WidgetCardGridLayout';
import { getToken } from '../../redux/sagas/app';
import * as Settings from '../../Settings';
import ConfigLoader from 'webcore-common/ConfigLoader';
import Logger from 'abb-webcore-logger/Logger';
import { CardGridLayoutProvider } from 'webcore-ux/nextgen/components/CardGridLayout';
// import testLayoutConfig from './layoutConfigTest.json';

type LandingPageProps = {
    className: string;
};

// JSS styling for this component
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        overflow: 'auto',
    },
    header: {
        margin: theme.spacing(1),
    },
    breadcrumb: {},
    actions: {
        textAlign: 'right',
    },
    editWidgetContainer: {},
    appGrid: {
        justifyContent: 'center',
    },
}));

/**
 * Dashboard component
 * @param {object} props - The props for the component
 * @returns {JSX.Element} - The component to render
 */
export default function LandingPage(props: LandingPageProps): ReactElement<LandingPageProps> {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { className } = props;
    const { authentication } = useSelector(getCombinedState);

    const [layoutConfig, setLayoutConfig] = useState(null);

    /**
     * get the config object for the grid layout
     * @returns {object} - the grid layout config
     */
    const getLayoutConfig = async () => {
        const baseOptions = { urlPrefix: Settings.getEamxHost() || '', getToken: getToken(authentication) };
        const resp: any = await ConfigLoader.loadConfigV1(Settings.getHomepageLayoutConfigId(), {
            ...baseOptions,
            defaultConfig: { error: 'config not found' },
        });

        if (resp.error || !resp.config) {
            Logger.error(`getLayoutConfig error: ${resp.error || 'invalid config detected'}: ${Settings.getHomepageLayoutConfigId()}`);
        }

        return resp.config;
    };

    useMemo(async () => {
        const resp = await getLayoutConfig().catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getLayoutConfig error:', error);
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLayoutConfig(resp);
    }, []);

    if (!layoutConfig) {
        return <div />;
    }

    return (
        <div className={classNames('abbes-landing-page-root', classes.root, className)} data-testid={'abbes-landing-page-root'}>
            <div className={classNames('abbes-landing-page-root-header', classes.header)} data-testid={'abbes-landing-page-root-header'}>
                <Grid container>
                    <Grid item xs={12} className={classNames('abbes-landing-page-breadcrumb', classes.breadcrumb)} />
                </Grid>
            </div>

            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <CardGridLayoutProvider cardGridLayoutConfig={layoutConfig}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <WidgetCardGridLayout
                    data-testid={'abbes-landing-page-grid-card-layout'}
                    getToken={getToken(authentication)}
                    host={Settings.getEamxHost()}
                />
            </CardGridLayoutProvider>
        </div>
    );
}

LandingPage.propTypes = {
    className: PropTypes.string,
};
